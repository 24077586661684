// Generated by Framer (d03ec02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {j1_FMD4Qq: {hover: true}};

const cycleOrder = ["j1_FMD4Qq"];

const serializationHash = "framer-rKmDr"

const variantClassNames = {j1_FMD4Qq: "framer-v-f3xj8y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, icon, id, tap, width, ...props}) => { return {...props, EfEHMrnJq: tap ?? props.EfEHMrnJq, J5MM5eXzr: icon ?? props.J5MM5eXzr ?? "CaretLeft"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, J5MM5eXzr, EfEHMrnJq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "j1_FMD4Qq", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1ao1eon = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (EfEHMrnJq) {
const res = await EfEHMrnJq(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-f3xj8y", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"j1_FMD4Qq"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1ao1eon} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"j1_FMD4Qq-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1b71vq4"} data-framer-name={"Border"} layoutDependency={layoutDependency} layoutId={"NKCrV3IOH"} style={{"--border-bottom-width": "0px", "--border-color": "rgba(0, 0, 0, 0)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, rotate: 0}} variants={{"j1_FMD4Qq-hover": {"--border-bottom-width": "1px", "--border-color": "var(--token-277735a3-39c6-44df-bac3-c373f0d2a4d4, rgb(255, 255, 255)) /* {\"name\":\"White\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "dashed", "--border-top-width": "1px", rotate: 90}}} {...addPropertyOverrides({"j1_FMD4Qq-hover": {"data-border": true}}, baseVariant, gestureVariant)}/><motion.div className={"framer-1memjs2-container"} layoutDependency={layoutDependency} layoutId={"n0xEhRhgc-container"}><Phosphor color={"var(--token-277735a3-39c6-44df-bac3-c373f0d2a4d4, rgb(255, 255, 255)) /* {\"name\":\"White\"} */"} height={"100%"} iconSearch={"House"} iconSelection={J5MM5eXzr} id={"n0xEhRhgc"} layoutId={"n0xEhRhgc"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"thin"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rKmDr[data-border=\"true\"]::after, .framer-rKmDr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rKmDr.framer-8ix3hi, .framer-rKmDr .framer-8ix3hi { display: block; }", ".framer-rKmDr.framer-f3xj8y { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 50px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 50px; }", ".framer-rKmDr .framer-1b71vq4 { flex: none; height: 100%; left: 0px; overflow: visible; position: absolute; top: 0px; width: 100%; z-index: 1; }", ".framer-rKmDr .framer-1memjs2-container { flex: none; height: 32px; position: relative; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rKmDr.framer-f3xj8y { gap: 0px; } .framer-rKmDr.framer-f3xj8y > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-rKmDr.framer-f3xj8y > :first-child { margin-left: 0px; } .framer-rKmDr.framer-f3xj8y > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"aROtKXgXE":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"J5MM5eXzr":"icon","EfEHMrnJq":"tap"}
 * @framerImmutableVariables true
 */
const FramerK8cFwirmd: React.ComponentType<Props> = withCSS(Component, css, "framer-rKmDr") as typeof Component;
export default FramerK8cFwirmd;

FramerK8cFwirmd.displayName = "Button/Arrow";

FramerK8cFwirmd.defaultProps = {height: 50, width: 50};

addPropertyControls(FramerK8cFwirmd, {J5MM5eXzr: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "CaretLeft", hidden: undefined, title: "Icon"}, EfEHMrnJq: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerK8cFwirmd, [...PhosphorFonts])